import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from 'src/app/core/user/user.service';

@Directive({
  selector: '[hasResource]',
  standalone: true,
})
export class UserResourceDirective {
  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _userService: UserService
  ) {}

  @Input() set hasResource(resourceType: any) {
    if (!resourceType || this._userService.hasResource(resourceType)) {
      this._viewContainer.createEmbeddedView(this._templateRef);
    } else {
      this._viewContainer.clear();
    }
  }
}
